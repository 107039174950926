<shared-loading-screen *ngIf="isInitializing"></shared-loading-screen>

<div class="Container" *ngIf="!isInitializing">
    <div class="Left-column">
        <img
            src="/assets/img/logo.png"
            style="height: 50px; margin-bottom: 24px; object-position: center; object-fit: contain"
        />
        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="usernameErrorTpl">
                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                        <input
                            type="text"
                            nz-input
                            formControlName="username"
                            placeholder="Utilizator"
                            spellcheck="false"
                            id="username"
                            autofocus
                        />
                    </nz-input-group>

                    <ng-template #prefixTemplateUser><i nz-icon nzType="user"></i></ng-template>

                    <ng-template #usernameErrorTpl let-control>
                        <ng-container
                            *ngIf="
                                validateForm.get('username').dirty &&
                                validateForm.get('username').errors
                            "
                        >
                            Va rugăm să introduceți utilizatorul
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                    <nz-input-group [nzPrefix]="prefixTemplatePassword">
                        <input
                            type="password"
                            nz-input
                            formControlName="password"
                            placeholder="Parolă"
                            spellcheck="false"
                        />
                    </nz-input-group>

                    <ng-template #prefixTemplatePassword><i nz-icon nzType="lock"></i></ng-template>

                    <ng-template #passwordErrorTpl let-control>
                        <ng-container
                            *ngIf="
                                validateForm.get('password').dirty &&
                                validateForm.get('password').errors
                            "
                        >
                            Va rugăm să introduceți parola
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <button
                        nz-button
                        class="login-form-button"
                        [nzType]="'primary'"
                        [nzLoading]="isLoading"
                        nzBlock
                    >
                        Autentificare
                    </button>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div class="Right-column"></div>
</div>
