import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    isLoading = false;
    isInitializing = true;

    versionCode: string;
    validateForm: UntypedFormGroup;

    constructor(public formBuilder: UntypedFormBuilder, private router: Router, private authService: AuthService) {}

    ngOnInit() {
        this.validateForm = this.formBuilder.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required, Validators.minLength(6)]]
        });

        // Focus the first input box on load
        window.onload = () => {
            const usernameElement = document.getElementById('username');
            if (usernameElement) usernameElement.focus();
        };

        const isAuthenticated = this.authService.isAuthenticated.getValue();

        if (isAuthenticated) {
            this.router.navigate(['/clienti']);
        } else {
            this.isInitializing = false;
        }
    }

    submitForm(): void {
        for (const i in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(i)) {
                this.validateForm.controls[i].markAsDirty();
                this.validateForm.controls[i].updateValueAndValidity();
            }
        }

        const { username, password } = this.validateForm.value;

        if (!username || !password) {
            return;
        }

        this.isLoading = true;
        this.authService
            .login(username, password)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.router.navigate(['/clienti']);
                },
                error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
    }
}
